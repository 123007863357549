<template>
  <div class="plan">
    <v-row v-if="ready">
      <v-col cols="12" lg="6">
        <v-card>
      <v-card-title>
          <h2>{{ plan.name }}</h2>
          <v-spacer></v-spacer>
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              @click="dialog = true"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              >
              <v-icon>mdi-file</v-icon>
              </v-btn>
            </template>
            <h4>REPORTS</h4>
          </v-tooltip>
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              @click="editPlan"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              >
              <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <h4>EDIT FIELDS</h4>
          </v-tooltip>
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
            @click="report = !report"
            dark
            icon
            v-bind="attrs"
            v-on="on"
            >
            <v-icon :color="report ? '#00ff44' : '#fff'">mdi-calculator</v-icon>
            </v-btn>
          </template>
          <h4>ADJUST ORDER</h4>
          </v-tooltip>
      </v-card-title>
      <v-data-table
      :headers="fieldHeaders"
      :items="tableValues"
      :items-per-page="rowsPerPage"
      @update:options="handleChangeTableOptions"
      class=""
      :loading="loading"
      no-data-text="No fields available"
      >
        <template
          v-slot:item.crop="{ item }"
        >
          {{ capitalize(item.crop) }}
        </template>
         <template
          v-slot:item.popRate="{ item }"
        >
          {{ item.variableRate ? `${item.avgRate} &#8478;` : item.popRate }}
        </template>
        <template v-slot:item.orderUnits="props">
          <v-edit-dialog
          >
          {{ props.item.orderUnits ? props.item.orderUnits : 0 }}
          <template v-slot:input>
              <v-text-field
              @focus="$event.target.select()"
              v-model="props.item.orderUnits"
              single-line
              type="number"
              @change="unitChange(props.item, $event)"
              ></v-text-field>
          </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
        </v-card>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card tile>
              <v-toolbar
                flat
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reports</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    dark
                    text
                    @click="generateReport(activeReport)"
                    :loading="pdfPrinting"
                  >
                    Print 
                  
                  </v-btn>
                  
                </v-toolbar-items>

              </v-toolbar>
              <v-tabs grow>
                <v-tabs-slider color="primary"></v-tabs-slider>
                
                <v-tab @click="clickedTab($event)">Crop Plan</v-tab>
                <v-tab @click="clickedTab($event)">Product Report</v-tab>
                <v-tab @click="clickedTab($event)">Product Portfolio</v-tab>
                <v-tab @click="clickedTab($event)">All Reports</v-tab>
                  
                  <v-tab-item>
                    <div>
                      <h2 id="warning">Tilt your phone to view and print reports properly</h2>
                    </div>
                    <br>
                    <div class='field-report'>
                        <div class="elevation-3">
                          <div class='fr-print' id="frprint">
                            <div class="header">
                              <img id="header-img" src="../assets/PFS_Header2.png">
                            </div>
                            <div class="fr-body" id="fr-body">
                             
                              <tr class='space-between top'>
                                <th class="title"><h2>{{ plan.name }}</h2></th>
                                
                                <th class="title"><h2> {{ customer.name }} </h2></th>
                              </tr>
                              <v-data-table
                              :headers="fieldReportHeaders"
                              :items="fields"
                              class=" field-report-table"
                              :loading="loading"
                              
                              :itemsPerPage="fields.length"
                              :hide-default-footer="true"
                              :hide-default-header="true"
                              :sort-by="['crop','brand']"
                              :sort-desc="[false,false]"
                              multi-sort
                              dense
                              id="fr-table"
                              >

                              <template slot="body.prepend">
                                <tr>
                                  <!-- <th v-if="brand.brand=='Peterson Farms Seed'" class="">PFS</th> -->
                                  <!-- <th v-if="brand.brand!='Peterson Farms Seed'" class="">{{brand.brand}}</th> -->
                                  <th class="" style="width:60pt;">Field Name</th>
                                  <th class="" style="width:40pt;">Crop</th>
                                  <th class="" style="width:107pt;">Brand</th>
                                  <th class="" style="width:43pt;">Product</th>
                                  <th class="" style="width:30pt;">Acres</th>
                                  <th class="" style="width:68pt;">Pop/Rate</th>
                                  <th class="" style="width:50pt;">Units</th>
                                </tr>
                              </template>

                              <template
                                v-slot:item.crop="{ item }"
                              >
                                {{ capitalize(item.crop) }}
                              </template>

                              <template
                                v-slot:item.popRate="{ item }"
                              >
                                {{ item.variableRate ? `${item.avgRate} &#8478;` : item.popRate }}
                              </template>

                              <template v-slot:item.calcUnits="{ item }">
                                <span v-if="!isNaN(item.calcUnits)">{{ Math.ceil(item.calcUnits) }}</span>
                                <span v-if="isNaN(item.calcUnits)">0</span>
                              </template>



                              <template slot="body.append">
                                <tr>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Total</th>
                                  <th class="">{{ sumField('acres').toFixed(2)  }}</th>
                                  <th class=""></th>
                                  <th class=""></th>
                                </tr>
                                <tr style="background-color:#cecece; color:black;">
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Corn Units</th>
                                  <th class="">{{ Math.ceil(sumField('calcUnits','corn').toFixed(4)) }}</th>
                                </tr>
                                <tr style="background-color:#cecece; color:black;">
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Soybean Units</th>
                                  <th class="">{{ Math.ceil(sumField('calcUnits','soybeans').toFixed(4))  }}</th>
                                </tr>
                                <tr v-for="crop in this.crops" style="background-color:#cecece; color:black;">
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">{{ capitalize(crop) }} Acres</th>
                                  <th class="">{{ Math.ceil(sumField('acres', crop)).toFixed(2)  }}</th>
                                </tr>
                              </template>
                              </v-data-table>
                              
                            </div>
                            <Map :report="true" ref="map" id="mappdf" :mapFields="mapFields" :scrollZoom="true"/>
                            <div class='footer'>
                              <img id="footer-img" src="../assets/PFS_Footer.png">
                              <div class="dealer">
                                <h5>PFS Local Customers</h5>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                                <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                                <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </section>
                      </vue-html2pdf> -->
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div>
                      <h2 id="warning">Tilt your phone to view and print reports properly</h2>
                    </div>
                    <br>
                    <div class='product-report'>
                      <div class="elevation-3">
                        <div class="pr-print" id="prprint">
                          <div class="header">
                            <img id="header-img" src="../assets/PFS_Header2.png">
                          </div>
                          <div class="pr-body" id="pr-body">
                            <tr class='space-between top'>
                              <th class="title"><h2>{{ plan.name }}</h2></th>
                              
                              <th class="title"><h2> {{ customer.name }} </h2></th>
                            </tr>
                            <tr class='custom-headers'>
                              <th class='custom-header'>Brand</th>
                              <th class='custom-header'>Crop</th>
                              <th class='custom-header'>Product</th>
                              <th class='custom-header'>Trait</th>
                              <!-- <th class='custom-header' style="width:116px;">Calc'd Units</th> -->
                              <th class='custom-header'>Order Units</th>
                            </tr>
                            <div v-for="brand in this.brandReport">
                              <v-data-table
                              :headers="productReportFilteredHeaders"
                              :items="productReportFiltered(brand.brand)"
                              class="product-report-table"
                              :loading="loading"
                              :itemsPerPage="productReport.length"
                              :hide-default-footer="true"
                              hide-default-header
                              sort-by="crop"
                              dense
                              >

                              <template
                                v-slot:item.crop="{ item }"
                              >{{ capitalize(item.crop) }}
                              </template>
                              <template slot="body.prepend">
                                <tr>
                                  <th class="custom-title" style="font-size:13px">{{brand.brand}}</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </template>
                              <template slot="body.append">
                                <tr style="background-color:#cecece">
                                  <th class="custom-title">{{brand.brand}} Total</th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <!-- <th class="" style="padding:0 0 0 17px; text-align:left;">{{Math.ceil(brand.calcUnits)}}</th> -->
                                  <th class="" style="font-size: 0.875rem;">{{orderUnitsBrandTotal(brand)}}</th>
                                </tr>
                              </template>
                              </v-data-table>
                            </div>
                            <tr class='custom-footers bottom'>
                              <th class='custom-footer'>Grand Total</th>
                              <th class='custom-footer'></th>
                              <th class='custom-footer'></th>
                              <th class='custom-footer'></th>
                              <!-- <th class='custom-footer' style="width:116px;">{{ sumField('calcUnits','onlyProduct') }}</th> -->
                              <th class='custom-footer'> {{ orderUnitsTotal() }}
                              </th>
                            </tr>
                          </div>
                          <div class='footer'>
                            <img id="footer-img"  src="../assets/PFS_Footer.png">
                            <div class="dealer">
                              <h5>PFS Local Customers</h5>
                              <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                              <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                              <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                              <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div>
                      <h2 id="warning">Tilt your phone to view and print reports properly</h2>
                    </div>
                    <br>
                    <div class='product-summary-report'>
                        <div class="elevation-3">
                          <div class='psr-print ' id="psrprint">
                            
                            <div class="header">
                              <img id="header-img" src="../assets/PFS_Header2.png">
                            </div>
                            <div class='psr-body' id="psr-body">
                              <tr class='space-between'>
                                <th class="title"><h2>{{ plan.name }}</h2></th>
                                
                                <th class="title"><h2> {{ customer.name }} </h2></th>
                              </tr>
                              <div class="product-imgs">
                                <img v-for="product in this.productPortfolio" v-if="product.product" class="product-img" :src="feedItemIcon(product)"> 
                              </div>
                            </div>
                            <div class='footer'>
                              <img id="footer-img" src="../assets/PFS_Footer.png">
                              <div class="dealer">
                                <h5>PFS Local Customers</h5>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                                <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                                <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div>
                      <h2 id="warning">Tilt your phone to view and print reports properly</h2>
                    </div>
                    <br>
                    <div class='field-report'>
                        <div class="elevation-3">
                          <div class='fr-print' id="frprint">
                            <div class="header">
                              <img id="header-img" src="../assets/PFS_Header2.png">
                            </div>
                            <div class="fr-body" id="fr-body-multi">
                             
                              <tr class='space-between top'>
                                <th class="title"><h2>{{ plan.name }}</h2></th>
                                
                                <th class="title"><h2> {{ customer.name }} </h2></th>
                              </tr>
                              <v-data-table
                              :headers="fieldReportHeaders"
                              :items="fields"
                              class=" field-report-table"
                              :loading="loading"
                              
                              :itemsPerPage="fields.length"
                              :hide-default-footer="true"
                              :hide-default-header="true"
                              :sort-by="['crop','brand']"
                              :sort-desc="[false,false]"
                              multi-sort
                              dense
                              id="fr-table"
                              >

                              <template slot="body.prepend">
                                <tr>
                                  <!-- <th v-if="brand.brand=='Peterson Farms Seed'" class="">PFS</th> -->
                                  <!-- <th v-if="brand.brand!='Peterson Farms Seed'" class="">{{brand.brand}}</th> -->
                                  <th class="" style="width:60pt;">Field Name</th>
                                  <th class="" style="width:40pt;">Crop</th>
                                  <th class="" style="width:107pt;">Brand</th>
                                  <th class="" style="width:43pt;">Product</th>
                                  <th class="" style="width:30pt;">Acres</th>
                                  <th class="" style="width:68pt;">Pop/Rate</th>
                                  <th class="" style="width:50pt;">Units</th>
                                </tr>
                              </template>

                              <template
                                v-slot:item.crop="{ item }"
                              >
                                {{ capitalize(item.crop) }}
                              </template>

                              <template
                                v-slot:item.popRate="{ item }"
                              >
                                {{ item.variableRate ? `${item.avgRate} &#8478;` : item.popRate }}
                              </template>

                              <template v-slot:item.calcUnits="{ item }">
                                <span v-if="!isNaN(item.calcUnits)">{{ Math.ceil(item.calcUnits) }}</span>
                                <span v-if="isNaN(item.calcUnits)">0</span>
                              </template>



                              <template slot="body.append">
                                <tr>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Total</th>
                                  <th class="">{{ sumField('acres').toFixed(2)  }}</th>
                                  <th class=""></th>
                                  <th class=""></th>
                                </tr>
                                <tr style="background-color:#cecece; color:black;">
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Corn Units</th>
                                  <th class="">{{ Math.ceil(sumField('calcUnits','corn').toFixed(4)) }}</th>
                                </tr>
                                <tr style="background-color:#cecece; color:black;">
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class="">Soybean Units</th>
                                  <th class="">{{ Math.ceil(sumField('calcUnits','soybeans').toFixed(4))  }}</th>
                                </tr>
                              </template>
                              </v-data-table>
                              
                            </div>
                            <Map :report="true" ref="map" id="mappdf-multi" :mapFields="mapFields" :scrollZoom="true"/>
                            <div class='footer'>
                              <img id="footer-img" src="../assets/PFS_Footer.png">
                              <div class="dealer">
                                <h5>PFS Local Customers</h5>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                                <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                                <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </section>
                      </vue-html2pdf> -->
                    </div>
                    <div class='product-report'>
                      <div class="elevation-3">
                        <div class="pr-print" id="prprint">
                          <div class="header">
                            <img id="header-img" src="../assets/PFS_Header2.png">
                          </div>
                          <div class="pr-body" id="pr-body-multi">
                            <tr class='space-between top'>
                              <th class="title"><h2>{{ plan.name }}</h2></th>
                              
                              <th class="title"><h2> {{ customer.name }} </h2></th>
                            </tr>
                            <tr class='custom-headers'>
                              <th class='custom-header'>Brand</th>
                              <th class='custom-header'>Crop</th>
                              <th class='custom-header'>Product</th>
                              <th class='custom-header'>Trait</th>
                              <!-- <th class='custom-header' style="width:116px;">Calc'd Units</th> -->
                              <th class='custom-header'>Order Units</th>
                            </tr>
                            <div v-for="brand in this.brandReport">
                              <v-data-table
                              :headers="productReportFilteredHeaders"
                              :items="productReportFiltered(brand.brand)"
                              class="product-report-table"
                              :loading="loading"
                              :itemsPerPage="productReport.length"
                              :hide-default-footer="true"
                              hide-default-header
                              sort-by="crop"
                              dense
                              >
                              <template
                                v-slot:item.crop="{ item }"
                              >
                                {{ capitalize(item.crop) }}
                              </template>
                              <template slot="body.prepend">
                                <tr>
                                  <th class="custom-title" style="font-size:13px">{{brand.brand}}</th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <!-- <th class="" style="width:80pt;"></th> -->
                                  <th class=""></th>
                                </tr>
                              </template>
                              <template slot="body.append">
                                <tr style="background-color:#cecece">
                                  <th class="custom-title">{{brand.brand}} Total</th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <th class=""></th>
                                  <!-- <th class="" style="padding:0 0 0 17px; text-align:left;">{{Math.ceil(brand.calcUnits)}}</th> -->
                                  <th class="" style="font-size: 0.875rem;">{{orderUnitsBrandTotal(brand)}}</th>
                                </tr>
                              </template>

                              </v-data-table>
                            </div>
                            <tr class='custom-footers bottom'>
                              <th class='custom-footer'>Grand Total</th>
                              <th class='custom-footer'></th>
                              <th class='custom-footer'></th>
                              <th class='custom-footer'></th>
                              <!-- <th class='custom-footer' style="width:116px;">{{ sumField('calcUnits','onlyProduct') }}</th> -->
                              <th class='custom-footer' > {{ orderUnitsTotal() }}
                              </th>
                            </tr>
                          </div>
                          <div class='footer'>
                            <img id="footer-img"  src="../assets/PFS_Footer.png">
                            <div class="dealer">
                              <h5>PFS Local Customers</h5>
                              <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                              <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                              <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                              <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div class='product-summary-report'>
                        <div class="elevation-3">
                          <div class='psr-print ' id="psrprint">
                            
                            <div class="header">
                              <img id="header-img" src="../assets/PFS_Header2.png">
                            </div>
                            <div class='psr-body' id="psr-body-multi">
                              <tr class='space-between'>
                                <th class="title"><h2>{{ plan.name }}</h2></th>
                                
                                <th class="title"><h2> {{ customer.name }} </h2></th>
                              </tr>
                              <div class="product-imgs">
                                <img v-for="product in this.productPortfolio" v-if="product.product" class="product-img" :src="feedItemIcon(product)"> 
                              </div>
                            </div>
                            <div class='footer'>
                              <img id="footer-img" src="../assets/PFS_Footer.png">
                              <div class="dealer">
                                <h5>PFS Local Customers</h5>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.given_name}} {{this.user.attributes.family_name}}</h6>
                                <h6 v-if="!this.user.attributes.given_name && !this.user.attributes.family_name && this.user.dealerInfo">{{this.user.dealerInfo.pfs_name}}</h6>
                                <h6 v-if="this.user.dealerInfo">{{this.user.dealerInfo.pfs_businessphone}}</h6>
                                <h6 v-if="this.user.attributes">{{this.user.attributes.email}}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </v-tab-item>
              </v-tabs>
              

              <div style="flex: 1 1 auto;"></div>
            </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" lg="6">
        <Map ref="map" :mapFields="mapFields" :scrollZoom="true" />
      </v-col>
    </v-row>
  </div>
  
</template>

<script>
import { mapState } from "vuex";
import Map from "@/components/Map";
import VueHtml2pdf from 'vue-html2pdf';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import Cookies from 'js-cookie'

export default {
  name: 'Plan',
  async created() {
    await this.$store.dispatch("plan/getPlan", this.$route.params.id)
    this.fields = this.plan.fields.items.map(item => {
      item.popRate = item.population ? item.population : item.rate
      item.calcUnits = this.calculate(item)
      return item
    }).sort((a,b) => a.fieldId > b.fieldId ? 1 : a.fieldId < b.fieldId ? -1 : 0)
    this.mapFields = this.getMapFeatures(this.fields)
    this.customer = await this.$store.dispatch("customer/getCRMCustomer", this.plan.customer.id)
    this.crops = [...new Set(this.fields.map(item => item.crop))]

    if(Cookies.get('itemsPerPage')) {
      this.rowsPerPage = parseInt(Cookies.get('itemsPerPage'))
    } else {
      this.rowsPerPage = 10;
    }
    this.ready = true

    this.imgSetup()
  },
  components: {
    Map,
    VueHtml2pdf
  },
  data() {
      return {
          ready: false,
          pdfPrinting: false,
          activeReport: 'Crop Plan',
          headerPNG: "",
          footerPNG: "",
          marginPNG: "",
          dialog: false,
          fields: [],
          orderUnitsBrandTotalArray: [],
          report: false,
          loading: false,
          customer: '',
          fieldReportHeaders: [
            {
                text: "Field Name",
                value: "name",
                sortable: false,
                // width: '70px'
            },
            {
                text: "Crop",
                value: "crop",
                sortable: false
            },
            {
                text: "Brand",
                value: "brand",
                sortable: false,
                width: '120px'
            },
            {
                text: "Product",
                value: "product",
                sortable: false
            },
            {
                text: "Acres",
                value: "acres",
                sortable: false
            },
            {
                text: "Population/Rate",
                value: "popRate",
                sortable: false
            },
            {
                text: "Calc'd Units",
                value: "calcUnits",
                sortable: false,
            },
          ],
          productReportHeaders: [
            {
                text: "Brand",
                value: "brand",
            },
            {
                text: "Crop",
                value: "crop"
            },
            {
                text: "Product",
                value: "product"
            },
            {
                text: "Calculated Units",
                value: "calcUnits"
            },
            {
                text: "Order Units",
                value: ""
            },
          ],
          productReportFilteredHeaders: [
            {
                text: "Brand",
                value: "",
                // align: 'start',
                sortable: false
            },
            {
                text: "Crop",
                value: "crop",
                // align: 'start',
                sortable: false
            },
            {
                text: "Product",
                value: "product",
                // align: 'start',
                sortable: false
            },
            {
                text: "Trait",
                value: "trait",
                sortable: false
            },
            // {
            //     text: "Calculated Units",
            //     value: "calcUnits",
            //     // align: 'end',
            //     sortable: false
            // },
            {
                text: "Order Units",
                value: "orderUnits",
                // align: 'end',
                sortable: false
            },
          ],
        }
  },
  computed: {
    ...mapState("plan", ["plan"]),
    ...mapState("user", ["user"]),
    products() {
      return new Set(this.fields.map(item => item.product))
    },
    tableValues() {
      if (!this.report) {
        return this.fields
      }
      else {
        return this.plan.products
        // return this.productReport
      }
    },
    fieldHeaders() {
      if (this.report) {
        return [
            {
                text: "Product",
                value: "product"
            },
            {
                text: "Crop",
                value: "crop"
            },
            {
                text: "Brand",
                value: "brand"
            },
            {
                text: "Trait",
                value: "trait"
            },
            {
                text: "Acres",
                value: "acres"
            },
            {
                text: "Calculated Units",
                value: "calcUnits"
            },
            {
                text: "Order Units",
                value: "orderUnits"
            }
          ]
      }
      else {
        return [
            {
                text: "Field Name",
                value: "name"
            },
            {
                text: "Crop",
                value: "crop"
            },
            {
                text: "Brand",
                value: "brand"
            },
            {
                text: "Product",
                value: "product"
            },
            {
                text: "Trait",
                value: "trait"
            },
            {
                text: "Acres",
                value: "acres"
            },
            {
                text: "Population/Rate",
                value: "popRate"
            },
            {
                text: "Calculated Units",
                value: "calcUnits"
            }
          ]
      }
    },
    productReport() {
      return Object.values(this.fields.reduce((acc, curr) => {
        if (acc[curr.product]) {
          acc[curr.product].acres = acc[curr.product].acres + curr.acres
          if (acc[curr.product].calcUnits) {
            acc[curr.product].calcUnits =  acc[curr.product].calcUnits + curr.calcUnits
          }
        }
        else {
          acc[curr.product] = {
            product: curr.product,
            brand: curr.brand,
            crop: curr.crop,
            trait: curr.trait,
            acres: curr.acres,
            calcUnits: curr.calcUnits
          }
        }
        return  acc
      }, {})).filter(item => item.product)
    },
    productPortfolio() {
      if (this.plan.products) {
        return this.plan.products.sort((a,b) => a.maturity > b.maturity ? 1 : a.maturity < b.maturity ? -1 : 0)
      }
      else return []
      // return Object.values(this.fields.reduce((acc, curr) => {
      //   if (acc[curr.product]) {
      //     acc[curr.product].acres = acc[curr.product].acres + curr.acres
      //     acc[curr.product].calcUnits =  acc[curr.product].calcUnits + curr.calcUnits
      //   }
      //   else {
      //     acc[curr.product] = {
      //       product: curr.product,
      //       brand: curr.brand,
      //       crop: curr.crop,
      //       trait: curr.trait,
      //       acres: curr.acres,
      //       calcUnits: curr.calcUnits
      //     }
      //   }
      //   return  acc
      // }, {})).sort((a,b)=> {
      //   if (a.crop < b.crop) {
      //     return 1;
      //   }
      //   else 
      //   return -1;
      // })
    },
    
    brandReport() {
      return Object.values(this.fields.reduce((acc, curr) => {
        if (acc[curr.brand]) {
          acc[curr.brand].acres = acc[curr.brand].acres + curr.acres
          if (curr.product) {
            acc[curr.brand].calcUnits =  Math.ceil(acc[curr.brand].calcUnits) + Math.ceil(curr.calcUnits)
          }
        }
        else {
          acc[curr.brand] = {
            brand: curr.brand,
            product: curr.product,
            crop: curr.crop,
            trait: curr.trait,
            acres: curr.acres,
            calcUnits: curr.calcUnits,
            orderUnits: curr.orderUnits
          }
        }

        return  acc
      }, {})).sort((a,b)=> {
        if (a.brand != 'Peterson Farms Seed') {
          return 1;
        }
        else return -1;
      })
    }
  },
  methods: {
    handleChangeTableOptions (val) {
      let newItemsPerPage = val.itemsPerPage;
      Cookies.set('itemsPerPage', newItemsPerPage);
    },
    imgSetup() {
      let headerPNG = require('@/assets/PFS_Header2.png')
      let footerPNG = require('@/assets/PFS_Footer.png')
      let marginPNG = require('@/assets/margin.png')
      let header = new Image()
      header.src = headerPNG
      this.headerPNG = header
      let footer = new Image()
      footer.src = footerPNG
      this.footerPNG = footer
      let margin = new Image()
      margin.src = marginPNG
      this.marginPNG = margin
    },
    unitChange(val) {
      this.$store.dispatch("plan/updatePlanProducts", {
        id: this.plan.id,
        products: this.plan.products
      })
    },
    clickedTab($event) {
      this.activeReport = $event.target.textContent;
    },
    getMapFeatures(val) {
      return val.map(item => {
          if (item.geoJson === "null") {
              return {
                  type: "Feature",
                  geometry: null,
                  properties: null
              }
          }
          else {
              return JSON.parse(item.geoJson)
          }
      })
    },
    printReport() {
      this.generateReport()
      return
      this.pdfPrinting = true;
      if (this.activeReport == 'All Reports') {
        this.generateFRReport('fr-body-multi','mappdf-multi');
        this.generatePRReport('pr-body-multi');
        this.generatePSRReport('psr-body-multi');
      }
        
      if (this.activeReport == 'Crop Plan') 
        this.generateFRReport('fr-body','mappdf');
      if (this.activeReport == 'Product Report')
        this.generatePRReport('pr-body');
      if (this.activeReport == 'Product Portfolio')
        this.generatePSRReport('psr-body');
    },
    async generateReport(type, img, map) {
      this.pdfPrinting = true

      // Crop Plan
      if (type === "Crop Plan") {
        // this.generateFRReport('fr-body', 'mappdf');
        await this.setupReport(true, 'fr-body', 'mappdf')
        this.pdfPrinting = false
      }
      // Product Report
      else if (type === "Product Report") {
        this.generatePRReport('pr-body');
      }
      // Product Portfolio
      else if (type === "Product Portfolio") {
        this.generatePSRReport('psr-body');
      }
      // All Reports
      else {
        this.generatePRReport('pr-body-multi');
        this.generatePSRReport('psr-body-multi');
        await this.setupReport(true, 'fr-body-multi','mappdf-multi');
        this.pdfPrinting = false;
      }
    },
    async setupReport(crop = false, img, map) {
      let pdf = new jsPDF({
        unit: 'px'
      })
      let pageWidth = pdf.internal.pageSize.getWidth()
      let pageHeight = pdf.internal.pageSize.getHeight()
      let data = document.getElementById(img)
      
      if (crop) {
        var mapData = document.getElementById(map)
        let htmlCanvas = html2canvas(data)
        let htmlMapCanvas = html2canvas(mapData)
        var [canvas, mapCanvas] = await Promise.all([htmlCanvas, htmlMapCanvas])
        var mapDataURL = mapCanvas.toDataURL('image/jpeg');
        var contentDataURL = canvas.toDataURL('image/jpeg');
      }
      else {
        var canvas = await html2canvas(data)
        var contentDataURL = canvas.toDataURL('image/jpeg');
      }
      
      
      let imgHeight = (canvas.height / canvas.width) * pageWidth 
      let totalHeight = imgHeight + 90
      let totalPageHeight = pageHeight - 30
      let position = 0
      pdf.addImage(this.headerPNG, 'PNG', 14, 5, pageWidth - 24, 80) // 5px margin y
      pdf.addImage(contentDataURL, 'JPEG', 0, 90, pageWidth, imgHeight)
      let mapPosition = 90 + imgHeight
      let onePage = mapPosition + 250 + 50
      // check if table and map can fit on 1 page
      if (onePage <= pageHeight) {
        pdf.addImage(mapDataURL, 'JPEG', 0, mapPosition, pageWidth, 250);
        pdf = this.createPdfFooter(pdf, pageWidth, pageHeight)
      }
      else {
        // check for remaining table to add to next page
        // if remaining table continue on next page and add margin to first
        if ( totalHeight > totalPageHeight ) {
          pdf.addImage(this.marginPNG, 'PNG', 0, totalPageHeight, pageWidth, 30)
          pdf.addPage()
          // 130px is necessary to end table on with line
          position = 162 - imgHeight
          if(this.crops) {
            position = 162 + (25 * this.crops.length) - imgHeight
          }
          pdf.addImage(contentDataURL, 'JPEG', 0, position, pageWidth, imgHeight);
          pdf.addImage(this.marginPNG, 'PNG', 0, 0, pageWidth, 25);
          mapPosition = imgHeight + position
          pdf.addImage(mapDataURL, 'JPEG', 0, mapPosition, pageWidth, 250)
          pdf = this.createPdfFooter(pdf, pageWidth, pageHeight)
        }
        // add map to second page
        else {
          pdf.addPage()
          pdf.addImage(mapDataURL, 'JPEG', 0, 25, pageWidth, 250)
          pdf = this.createPdfFooter(pdf, pageWidth, pageHeight)
        }
        // pdf.addImage(this.marginPNG, 'PNG', 0, totalPageHeight, pageWidth, 30)
        // pdf.addPage()
      }
      
      // console.log("total height", totalHeight)
      // console.log("total page height", totalPageHeight)
      // check if report needs to be added to second page
      
      // if (totalHeight > totalPageHeight) {
      //   // remaining section of table
      //   position = 130 - imgHeight
      //   pdf.addImage(contentDataURL, 'JPEG', 0, position, pageWidth, imgHeight);
      //   pdf.addImage(this.marginPNG, 'PNG', 0, 0, pageWidth, 25);
      //   mapPosition = imgHeight + position
      // }
      
      // console.log("map position", mapPosition)

      // pdf.addImage(mapDataURL, 'JPEG', 0, mapPosition, pageWidth, 250);
      
      pdf.save(`Crop Plan ${this.customer.name} ${this.plan.name}`);
    },
    createPdfFooter(pdf, pageWidth, pageHeight) {
      pdf.addImage(this.footerPNG, 'PNG', 0,  pageHeight - 50, pageWidth, 50);
      pdf.setFontSize(10);
      pdf.text('PFS Local Customers', pageWidth - 110, pageHeight - 33)
      if (this.user.attributes && this.user.attributes.given_name && this.user.attributes.family_name) {
        pdf.setFontSize(9);
        pdf.text(`${this.user.attributes.given_name} ${this.user.attributes.family_name}`, pageWidth - 110, pageHeight - 26);
      }

      if (this.user.dealerInfo && this.user.dealerInfo.pfs_businessphone) {
        pdf.setFontSize(9);
        pdf.text(`${this.user.dealerInfo.pfs_businessphone}`,pageWidth - 110, pageHeight - 18);
      }

      if (this.user.attributes) {
        pdf.setFontSize(8);
        pdf.text(`${this.user.attributes.email}`, pageWidth - 110, pageHeight - 11)
      }
      return pdf
    },
    async generateFRReport(divId,mapId) {      
      let data = document.getElementById(divId);
      let mapData = document.getElementById(mapId);
      let htmlCanvas = html2canvas(data)
      let htmlMapCanvas = html2canvas(mapData)

      //Generates PDF in portrait mode
      let pdf = new jsPDF({
        unit: 'px'
      });
      // let pdf = new jsPDF({
      //   unit: 'px',
      //   format: [2480,3508]
      // });
      
      // 595 x 842
      // 2480 x 3508
      

      
        
      let imgHeight = (canvas.height * pageWidth) / canvas.width + 135;
        let heightLeft = imgHeight;
        // let position = 0;
        // pdf.addImage(this.headerPNG, 'PNG', -10, 10, pageWidth + 23, 80); // 90
        pdf.addImage(this.headerPNG, 'PNG', 0, 10, pageWidth, 80); // 90
        pdf.addImage(contentDataURL, 'JPEG', 0, 99, pageWidth, imgHeight); // 100 + imgHeight
        pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight - 30, pageWidth, 30);

        // Calculate imgHeight + 120px(margin top and bottom)
        // if (imgHeight + 110 > pageHeight) {
        //   let extraPages = Math.ceil((imgHeight + 120) / pageHeight) - 1
        //   while (extraPages > 0) {
        //     pdf.addPage()
        //     extraPages--
        //   }
        // }

        pdf.addPage()

        let position = 160 - imgHeight
        console.log("position", position)
        let newPosition = position + imgHeight + 10
        
        pdf.addImage(contentDataURL, 'JPEG', 0, position, pageWidth, imgHeight);
        pdf.addImage(this.marginPNG, 'PNG', 0, 0, pageWidth, 30);
        //   pdf.addImage(this.marginPNG, 'PNG', 0, 0 ,pageWidth,25);
        //   pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-15 ,pageWidth,15);

        // heightLeft -= pageHeight; //For Multi Page Report - bottom and top margins are the issue otherwise it works

        // while (heightLeft >= -135) {
        //   position = heightLeft - imgHeight;
        //   pdf.addPage();
        //   pdf.addImage(contentDataURL, 'JPEG', 0, position + 120, pageWidth, imgHeight);
        //   pdf.addImage(this.marginPNG, 'PNG', 0, 0 ,pageWidth,25);
        //   pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-15 ,pageWidth,15);

        //   heightLeft -= pageHeight;
        // }
        pdf.addImage(mapDataURL,'JPEG', 11, newPosition, pageWidth - 22, 250);

        let mapPosition = newPosition + 250
        pdf.addImage(this.footerPNG, 'PNG', 0,  pageHeight - 50, pageWidth, 55);
        
        pdf.setFontSize(10);
        pdf.text('PFS Local Customers',pageWidth-110,pageHeight-33)
        
        if (this.user.attributes && this.user.attributes.given_name && this.user.attributes.family_name) {
          pdf.setFontSize(9);
          pdf.text(`${this.user.attributes.given_name} ${this.user.attributes.family_name}`,pageWidth-110,pageHeight - 26);
        }

        if (this.user.dealerInfo && this.user.dealerInfo.pfs_businessphone) {
          pdf.setFontSize(9);
          pdf.text(`${this.user.dealerInfo.pfs_businessphone}`,pageWidth-110,pageHeight-18);
        }

        if (this.user.attributes) {
          pdf.setFontSize(8);
          pdf.text(`${this.user.attributes.email}`,pageWidth-110,pageHeight - 11)
        }
        
        this.pdfPrinting = false;
        pdf.save(`Crop Plan ${this.customer.name} ${this.plan.name}`); 

    },
    generatePRReport(divId) {
      
      let data = document.getElementById(divId); 
      html2canvas(data).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/jpeg');

        let pdf= new jsPDF('p', 'px', 'a4'); //Generates PDF in portrait mode
        var pageWidth = pdf.internal.pageSize.getWidth();
        var pageHeight = pdf.internal.pageSize.getHeight();
        
        const imgHeight = (canvas.height * pageWidth) / canvas.width + 135;
        let heightLeft = imgHeight;
        let position = 0;


        pdf.addImage(this.headerPNG, 'PNG', 0, 10, pageWidth, 80);
        pdf.addImage(contentDataURL,'JPEG', 0, 99, pageWidth, imgHeight);
        pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-35 ,pageWidth,35);
        

        heightLeft -= pageHeight; 

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'JPEG', 0, position+137, pageWidth, imgHeight);
          pdf.addImage(this.marginPNG, 'PNG', 0, 0 ,pageWidth,25);
          
          pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-15 ,pageWidth,15);


          heightLeft -= pageHeight;
        }
        pdf.addImage(this.footerPNG, 'PNG', 0,  pageHeight-50, pageWidth,55);
        
        // pdf.setFont("Oswald", "sans-serif",'bold')

        pdf.setFontSize(10);
        pdf.text('PFS Local Customers',pageWidth-110,pageHeight-33)
        
        if (this.user.attributes && this.user.attributes.given_name && this.user.attributes.family_name) {
          pdf.setFontSize(9);
          pdf.text(`${this.user?.attributes?.given_name} ${this.user.attributes.family_name}`,pageWidth-110,pageHeight-26);
        }

        if (this.user.dealerInfo && this.user.dealerInfo.pfs_businessphone) {
          pdf.setFontSize(9);
          pdf.text(`${this.user?.dealerInfo?.pfs_businessphone}`,pageWidth-110,pageHeight-18);
        }

        if (this.user.attributes) {
          pdf.setFontSize(8);
          pdf.text(`${this.user.attributes.email}`,pageWidth-110,pageHeight-11)
        }


        this.pdfPrinting = false;
        pdf.save(`Product Report ${this.customer.name} ${this.plan.name}`);  
      })
    },
    generatePSRReport(divId) {
      let data = document.getElementById(divId); 
      html2canvas(data).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/jpeg');

        let pdf= new jsPDF('p', 'px', 'a4'); //Generates PDF in portrait mode
        var pageWidth = pdf.internal.pageSize.getWidth();
        var pageHeight = pdf.internal.pageSize.getHeight();
        
        const imgHeight = (canvas.height * pageWidth) / canvas.width + 135;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(this.headerPNG, 'PNG', 0, 10, pageWidth, 80);
        pdf.addImage(contentDataURL,'JPEG', 0, 99, pageWidth, imgHeight);
        pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-35 ,pageWidth,35);

        heightLeft -= pageHeight; //For Multi Page Report - bottom and top margins are the issue otherwise it works

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'JPEG', 0, position+137, pageWidth, imgHeight);
          pdf.addImage(this.marginPNG, 'PNG', 0, 0 ,pageWidth,25);
          
          pdf.addImage(this.marginPNG, 'PNG', 0, pageHeight-15 ,pageWidth,15);


          heightLeft -= pageHeight;
        }
        pdf.addImage(this.footerPNG, 'PNG', 0,  pageHeight-50, pageWidth,55);
        
        pdf.setFontSize(10);
        pdf.text('PFS Local Customers',pageWidth-110,pageHeight-33)
        
        if (this.user.attributes && this.user.attributes.given_name && this.user.attributes.family_name) {
          pdf.setFontSize(9);
          pdf.text(`${this.user?.attributes?.given_name} ${this.user.attributes.family_name}`,pageWidth-110,pageHeight-26);
        }

        if (this.user.dealerInfo && this.user.dealerInfo.pfs_businessphone) {
          pdf.setFontSize(9);
          pdf.text(`${this.user?.dealerInfo?.pfs_businessphone}`,pageWidth-110,pageHeight-18);
        }

        if (this.user.attributes) {
          pdf.setFontSize(8);
          pdf.text(`${this.user.attributes.email}`,pageWidth-110,pageHeight-11)
        }  
        
        this.pdfPrinting = false;
        pdf.save(`Product Portfolio ${this.customer.name} ${this.plan.name}`);  

      })
    },
    sumField(key,type="default") {
        // sum data in give key (property)
        if (type=="default") {
          return this.fields.reduce((a, b) => Math.ceil(a) + Math.ceil((b[key] || 0)), 0)
        }
        if (type=="onlyProduct") {
          return this.fields.reduce((a, b) =>  Math.ceil(a) + Math.ceil((b.product != '') ? (b[key] || 0) : 0), 0)
        }
        if (type=="soybeans") {
          let fields_by_crop = this.fields.filter( (element) => {
            return element.crop == type;
          })
         
          return fields_by_crop.reduce((a, b) => Math.ceil(a) + Math.ceil((b[key] || 0)), 0 )
        }
        else if (type=="corn") {
          let fields_by_crop = this.fields.filter( (element) => {
            return element.crop == type;
          })
         
          return fields_by_crop.reduce((a, b) => Math.ceil(a) + Math.ceil((b[key] || 0)), 0 )
        }
        else {
          let fields_by_crop = this.fields.filter( (element) => {
            return element.crop == type;
          })
          return fields_by_crop.reduce((a, b) => Math.ceil(a) + Math.ceil((b[key] || 0)), 0 )
        }
        
    },
    calculate(item) {
      if (item.crop === "soybeans") {
        return Math.ceil((item.population / 140000) * item.acres)
      }
      else if (item.crop === "corn") {
        return Math.ceil(((item.variableRate ? item.avgRate : item.rate) / 80000) * item.acres)
      }
    },
    editPlan() {
      this.$router.push(`/plans/${this.plan.id}/edit`)
    },
    capitalize(val) {
      return `${val.slice(0,1).toUpperCase()}${val.slice(1)}`
    },
    mapLoaded(val) {
      this.fields = this.$refs.map.customerGeo.features.map(item => {
          let { properties } = item
          return {
              landId: properties.fmid_land_id,
              lat: properties.lat,
              lon: properties.lon,
              acres: properties.acres,
              county: properties.county,
              crop: properties.crop,
              zip: properties.zip_cd
          }
      })
    },
    feedItemIcon(product) {
      try {
        if (product.brand == "Peterson Farms Seed") {
          return require(`../assets/pfsimages/${product.product}.jpg`);
        }
        else return ''
      } catch (error) {
        return ''
      }
    },
    productReportFiltered(brand) {  
      if (this.plan.products) {
        return this.plan.products.filter( (val) => {
          return val.brand == brand
        })
      }
      else return []
    },
    orderUnitsBrandTotal(brand) {
      if (this.plan.products) {
        return this.plan.products.reduce((a,b) => Number(a) + (b.brand == brand.brand ? (Number(b['orderUnits']) || 0) : 0),0)
      }
      else return 0
      // var total=0;
      // for (let entry of this.orderUnitsBrandTotalArray) {
      //   if (brand.brand == entry.brand) {
      //     total += entry.orderUnits;
      //   }
      // }
      // // if (total != 0)
      //   return total;
    },
    orderUnitsTotal() {
      // var total=0;
      // for (let entry of this.orderUnitsBrandTotalArray) {
      //   total += entry.orderUnits;
      // }
      // return total;
      if (this.plan.products) {
        return this.plan.products.reduce((a,b) => Number(a) +  (Number(b['orderUnits']) || 0),0)
      }
      else return 0
    },
    setdata($event,inputBrand,item) {
      var foundProduct = false;
      var foundBrand = false;
      var foundNewProduct = false;

      var value = Number($event.target.value);

      for (let entry of this.orderUnitsBrandTotalArray) {
        if (inputBrand == entry.brand && item.product == entry.product) {
          entry.orderUnits = value;
          
          foundBrand = true;
          foundProduct = true;
        }

        if (inputBrand == entry.brand && item.product != entry.product && !foundProduct) {          
          foundProduct = true;
          foundNewProduct = true;
        }
      }
    
      if (!foundBrand && !foundProduct) {
        var pair = {
          'brand': inputBrand,
          'product': item.product,
          'orderUnits': value
        }
        // console.log('pushed new brand values',pair)
        this.orderUnitsBrandTotalArray.push(pair)
      }
      if (foundNewProduct && !foundBrand) {
        var pair = {
          'brand': inputBrand,
          'product': item.product,
          'orderUnits': value
        }
        // console.log('pushed new product values',pair)
        this.orderUnitsBrandTotalArray.push(pair)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title{
  background-color: #015a9c;
  color: #fff;
}


tr td {
  color: red !important;
}

.custom-headers {
  display:flex;
  justify-content: flex-start;
  background-color: #fff;
  padding: 6px 0px;
  color: #000;
  font-size: 14px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.custom-footers {
  display:flex;
  justify-content: flex-start;
  background-color: #015a9c;
  padding: 6px 0px;
  color: white;
}

.space-between {
  display: flex;
  justify-content: space-between;
  background-color: #015a9c;
  padding: 5px 10px 10px 10px;
  color: white;
}

// .top {
//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
// }

// .bottom {
//   border-bottom-left-radius: 3px;
//   border-bottom-right-radius: 3px;
// }


.field-report {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;  
}

.product-report {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.product-summary-report {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

}

.header {
  height: 152px;
  #header-img {
    width: 800px;
    padding: 0 20px;
  }
  th {
    h2 {
      margin: 38px 28px 0px 15px;
    };
  }
}

.footer {
  height: 100px;
  #footer-img {
    width: 800px;
  }
  bottom: 0;
  position: relative;
  display: flex;
  .dealer {
    position: absolute;
    top: 19px;
    right: 45px;
    h5 {
      margin: 0;
      padding: 0;
    }
  }
}

.fr-print {
  width: 800px;
  max-height: 100%;
  
}

.pr-print {
  width: 800px;
  max-height: 100%;
}

.psr-print {
  width: 800px;
  max-height: 100%;
}

.fr-body {
  // height: 100%;
  // min-height: 835px;
  padding: 0 20px 20px 20px;
}

#fr-table {
  height:100%;
  th {
    padding: 0 12px;
  }
}

.pr-body {
  // height: 100%;
  min-height: 835px;
  padding: 0 20px 20px 20px;
  table {
    tr {
      padding: 0 6px;
      th {
        width: 20%;
        padding: 0 12px !important;
      }
    }
  } 
}

.psr-body {
  // height: 100%;
  min-height: 835px;
  padding: 0 20px 20px 20px;
}

.product-img {
  max-width:755px;
  max-height: 282px;
}
.img-placeholder {
  min-height:0px;
}

// .custom-title {
//   width:120pt
//   margin:0; 
//   padding:0 0 0 5px; text-align:left;
// }

.custom-header, .custom-footer {
  width: 20%; 
  padding:0 12px; 
  text-align: left;
}
::v-deep .text-start {
  padding:0 12px !important; 
}

.custom-margin {
  margin: 10px 0 10px 0;
  height: 10px;
}

@media only screen and (max-width: 600px) {
  h1 {
    width: 40%;
    align-content: center;
    text-align: center;
    border: 1px solid;
    padding: 1rem 1rem;
    background-color: #0b3a7f;
    color: white;
    text-transform: uppercase;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top:35px;
    margin-left: auto;
    margin-right: auto;
      
  }

  h1:hover {
    background-color: #4167a0;

  }

  #warning {
    width: 90%;

    display:block;
    
    align-content: center;
    text-align: center;
    border: 1px solid;
    padding: 1rem 1rem;
    background-color: #d83333;
    color: white;
    text-transform: uppercase;
    font-size: 1.1rem;
    // margin-bottom: 2rem;
    margin-top:35px;
    margin-left: auto;
    margin-right: auto;

  }

  .elevation-3 {
   
    transform: scale(0.4) translateY(-74%);
  }

}





@media only screen and (min-width: 601px) {
#warning {
  display: none;
}
h1 {
  width: 15%;
  align-content: center;
  text-align: center;
  border: 1px solid;
  padding: 1rem 1rem;
  background-color: #0b3a7f;
  color: white;
  text-transform: uppercase;
  font-size: 1.1rem;
  // margin-bottom: 2rem;
  cursor: pointer;
  animation: 500ms;
  // margin-top:35px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 23px;
  right: 90px;
    
  }

  h1:hover {
    background-color: #4167a0;

  }
}

#mappdf {
  padding: 0 20px !important;
  .mapboxgl-ctrl-bottom-right{
    display: none !important;
  }
}

</style>